import React from 'react';
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Social from "../components/social.js"
import F1 from "../images/f1.jpg"
import F2 from "../images/f2.jpg"
import F3 from "../images/f3.jpg"
import MDEV from "../images/Mdev.jpg"

import C1 from "../images/c1.jpg"
import C2 from "../images/c2.jpg"
import C3 from "../images/c3.jpg"
import C4 from "../images/c4.jpg"

import Layout from "../components/LayoutAlt.js"


import { FaShoePrints, FaTree } from "react-icons/fa"




const Lifestyles = () =>{

    return(
        <Layout>

      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Are you living according to the Word of God?" />
        <title>ReaCH | Lifestyles</title>
        <link rel="canonical" href="https://reachun.org/lifestyles" />
      </Helmet>

          <section className="sm:w-3/4 sm:ml-1/8 xs:mt-10 sm:mt-20 mb-20 xs:px-2 sm:px-0">
           <h1 className="font-montserrat font-bold xs:text-3xl sm:text-5xl text-blue-alt mb-10">The Lifestyle of God's People</h1>
           <div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-8 text-gray-700 ">
           
             <div>
             <div className="border-t border-gray-300 w-64"></div>
              <p className="font-montserrat font-bold text-blue-alt pt-10 mb-10">When Faith Walks</p>
              <div className="grid grid-cols-4 mb-10">
              <div><img src={F1} alt="family" className="object-cover w-full h-32" /></div>
              <div><img src={F2} alt="family" className="object-cover w-full h-32" /></div>
              <div><img src={F3} alt="family" className="object-cover w-full h-32" /></div>
              <div><img src={MDEV} alt="family" className="object-cover w-full h-32" /></div>
              </div>
              <p className="font-opensans font-semibold mb-4">Hebrews 11:6</p>
              <p className="font-opensans pb-10">6 But without faith it is impossible to please him: for he that cometh to God must believe that he is, and that he is a rewarder of them that diligently seek him.</p>
              <div className="border-t border-gray-300 w-64"></div>
              <Link to="/lifestyles/faithwalks">
              <p className="mt-10 text-blue-alt hover:text-white hover:bg-blue-alt border-2 border-blue-alt shadow-xl flex items-center justify-center rounded-lg h-12 w-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              <FaShoePrints size="32"/>
              </p>
              </Link>
             </div>

             <div className="xs:pt-10 lg:pt-0">
             <div className="border-t border-gray-300 w-64"></div>
              <p className="font-montserrat font-bold text-blue-alt pt-10 mb-10">Country Living</p>
              <div className="grid grid-cols-4 mb-10">
              <div><img src={C1} alt="family" className="object-cover w-full h-32" /></div>
              <div><img src={C2} alt="family" className="object-cover w-full h-32" /></div>
              <div><img src={C3} alt="family" className="object-cover w-full h-32" /></div>
              <div><img src={C4} alt="family" className="object-cover w-full h-32" /></div>
              </div>
              <p className="font-opensans font-semibold mb-4">Hebrews 11:16</p>
              <p className="font-opensans pb-10">16 But now they desire a better country, that is, an heavenly: wherefore God is not ashamed to be called their God: for he hath prepared for them a city. </p>
              <div className="border-t border-gray-300 w-64"></div>
              <Link to="/lifestyles/country">
              <p className="mt-10 text-blue-alt hover:text-white hover:bg-blue-alt border-2 border-blue-alt shadow-xl flex items-center justify-center rounded-lg h-12 w-12 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
              <FaTree size="32"/>
              </p>
              </Link>
             </div>
           </div>
          </section>

        <Social />
        </Layout>
        
    )
}

export default Lifestyles